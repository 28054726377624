import Rails from "@rails/ujs";
import 'bootstrap/js/dist/tab';

const pausePlayer = (application_id) => {
  $("#explanation_player_" + application_id).pause();
}

document.addEventListener("turbolinks:load", function() {
  $(".explanation_player").each((index, value) => {
    console.log("ended");
    value.addEventListener("ended", function(){
      console.log(value)
      $("#agree_contract_" + value.getAttribute("application_id")).removeAttr("disabled");
      var application_id = value.getAttribute("application_id");
    });
  });
  $(".agree_contract").each((index, value) => {
    value.addEventListener("change", function(){
      $("#download_contract_" + value.getAttribute("application_id")).toggleClass("disabled");
      
    });
  });
  $(".download_contract_button").each((index, value) => {
    value.addEventListener("click", function() {
      var download_contract_form = document.getElementById("download_contract_form_" + value.getAttribute("application_id"));
      Rails.fire(download_contract_form, 'submit');
    });
  });  
});
